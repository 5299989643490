@tailwind base;
@tailwind components;
@tailwind utilities;

.bookmark-witch {
    background-image: url("../public/images/SeasonWitch003.png");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-haunting {
    background-image: url("../public/images/HauntingOfNewCoverCropped.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-seer {
    background-image: url("../public/images/seer.png");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-spellbound {
    background-image: url("../public/images/spellbound.png");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-spirit {
    background-image: url("../public/images/SpiritCoverFinal-team.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-lycan {
    background-image: url("../public/images/Lycan02-greenremoved.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-wordsmith {
    background-image: url("../public/images/Journal01-1600.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-zombies {
    background-image: url("../public/images/ZombieCell04-Cartoon.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-faeries {
    background-image: url("../public/images/fairywings003-green_cropped.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    background-size: 175px 200px;
}

.bookmark-text-witch {
    transform: rotate(-90deg);
    font-family: 'IM Fell English SC', serif;
}

.bookmark-text-haunting {
    transform: rotate(-90deg);
    font-family: 'Fredericka the Great', cursive;
}

.bookmark-text-seer {
    transform: rotate(-90deg);
    font-family: 'Mountains of Christmas', cursive;
}

.bookmark-text-spellbound {
    transform: rotate(-90deg);
    font-family: 'Macondo', cursive;
}

.bookmark-text-spirit {
    transform: rotate(-90deg);
    font-family: 'Nosifer', cursive;
}

.bookmark-text-lycan {
    transform: rotate(-90deg);
    font-family: 'Permanent Marker', cursive;
}

.bookmark-text-wordsmith {
    transform: rotate(-90deg);
    font-family: 'Mountains of Christmas', cursive;
}

.bookmark-text-zombie {
    transform: rotate(-90deg);
    font-family: 'Permanent Marker', cursive;
}

.bookmark-text-faeries {
    transform: rotate(-90deg);
    font-family: 'Macondo', cursive;
}
